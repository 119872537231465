import React from 'react'
import { FaArrowCircleRight } from "react-icons/fa";

export default function Vision() {
    return (
        <div className='container my-3   m-auto p-4' >
            <div className='text-[40px]'>
                <h1>Vision & Mission</h1>
            </div>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
                <div className='py-2'>
                    <img src="image/vision/1.jpg" alt="" />
                </div>
                <div className='flex flex-col gap-4 p-3'>
                    <h1 className='text-[35px]'>OUR VISION</h1>
                    <div className='flex items-center gap-5'>
                        <FaArrowCircleRight className='text-[30px]' />
                        <p>To expand our presence in domestic as well as International Markets by sharpening our business portfolio's focus on our core Bearings and allied products.</p>

                    </div>
                    <div className='flex items-center gap-5'>
                        <FaArrowCircleRight className='text-[30px]' />
                        <p>To be actively engaged in enhancing the productivity and efficiency of our product's along with innovative development strategies.</p>
                    </div>
                </div>

            </div>
            <div className='grid grid-cols-1 gap-4 md:grid-cols-2'>
               
                <div className='flex flex-col gap-4 p-3'>
                    <h1 className='text-[35px]'>OUR MISSION</h1>
                    <div className='flex items-center gap-5'>
                        <FaArrowCircleRight className='text-[30px]' />
                        <p>To expand our presence in domestic as well as International Markets by sharpening our business portfolio's focus on our core Bearings and allied products.</p>

                    </div>
                    <div className='flex items-center gap-5'>
                        <FaArrowCircleRight className='text-[30px]' />
                        <p>To be actively engaged in enhancing the productivity and efficiency of our product's along with innovative development strategies.</p>
                    </div>
                </div>
                <div className='py-2'>
                    <img src="image/vision/2.jpg" alt="" />
                </div>

            </div>

        </div>
    )
}
