import React from 'react'

export default function KG() {
    const kgbrand = [
        {
            img: "image/kgbrand/1.jpg"
        },
        {
            img: "image/kgbrand/5.jpg"
        },
        {
            img: "image/kgbrand/3.jpg"
        }


    ]

    return (
        <>

            <div className='container m-auto bg-[#f1eeee]'>
                <div className='   grid md:grid-cols-3 grid-cols-1'>
                    <div className=' flex items-center m-5 justify-center  text-center  col-span-1 rounded-lg'>
                        <img className=' w-[200px] h-[200px]  rounded-lg  ' src="image/kgbrand/logo.png" alt="" />
                    </div>
                    <div className='col-span-2 m-5'>
                        <h1 className='font-bold text-[35px]'>
                            KG BRAND
                        </h1>
                        <p className='py-5 box-border text-[20px]'>KG is a globally renowned brand for over 53+ years. It offers high-quality and reliable products at a quality-price balance that makes customers more competitive, productive, and profitable. KG’s quality control practices are industry leading that always ensure consistent and reliable products. KG also has an extensive manufacturing network that provides business resiliency and a quick ability to manufacture fit-for-purpose bearings based on customer needs.</p>

                    </div>

                </div>
                <div className='m-5'>
                    <h1 className='text-[30px] font-medium'>Products</h1>
                    <div className=' container m-auto grid md:grid-cols-3 grid-cols-1 '>
                        {
                            kgbrand.map(
                                (d, i) => {
                                    return (
                                        <div className=' border-[3px] rounded-[20px] border-green-500  m-2'>
                                            <img src={d.img} className='rounded-[15px] w-full h-[200px]' alt="" />
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                </div>
            </div>

        </>

    )

}
