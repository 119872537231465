import React from 'react'

export default function Rsi() {
    const rsibrand = [
        {
            img: "image/rsibrand/1.jpg"
        },
        {
            img: "image/rsibrand/2.jpg"
        },
        {
            img: "image/rsibrand/3.jpg"
        }


    ]

    return (
        <>
  
         <div className='container m-auto bg-[#f1eeee]'>
            <div className='   grid md:grid-cols-3 grid-cols-1'>
                <div className=' flex items-center m-5 justify-center  text-center  col-span-1 rounded-lg'>
                    <img className=' w-[200px] h-[200px]  rounded-lg  ' src="image/rsibrand/logo.jpg" alt="" />
                </div>
                <div className='col-span-2 m-5'>
                    <h1 className='font-bold text-[35px]'>
                        RSI BRAND
                    </h1>
                    <p className='py-5 box-border text-[20px]'>Since 2005, Rudra Steel Industries has been manufacturing bearings for the aftermarket and industries ranging from high-speed and high-precision bearings with small diameters to large size bearings several inches wide. At Rudra Steel Industries, we strongly believe in perfection in all the processes we do. We are a young and energetic company focusing on sheer perfection in all the products that we manufacture and all the processes that we perform.</p>

                </div>

            </div>
            <div className='m-5'>
                <h1 className='text-[30px] font-medium'>Products</h1>
                <div className=' container m-auto grid md:grid-cols-3 grid-cols-1 '>
                    {
                        rsibrand.map(
                            (d, i) => {
                                return (
                                    <div className=' border-[3px] rounded-[20px] border-green-500  m-2'>
                                        <img src={d.img} className='rounded-[15px] w-full h-[200px]' alt="" />
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </div>
        </div>
        </>
       
    )

}
