import React from 'react'
import Eaqury from '../Eaqury'
import Brand from '../componate/Brand'
export default function BrandPage() {
  return (
    <>
      <Brand/>
      <Eaqury />
    </>
  )
}
