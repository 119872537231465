import React from 'react'
import Carousel from "react-multi-carousel";


export default function About() {

 const   brand=[
        {
            img:"image/homecrousel/1.jpg"
        },
        {
            img:"image/homecrousel/3.jpg"
        },
        {
            img:"image/homecrousel/4.jpg"
        },
        {
            img:"image/homecrousel/5.jpg"
        },
        {
            img:"image/homecrousel/6.jpg"
        },

        {
            img:"image/homecrousel/7.jpg"
        },

        {
            img:"image/homecrousel/8.jpg"
        },
        {
            img:"image/homecrousel/9.jpg"
        },
        {
            img:"image/homecrousel/10.jpg"
        },
        {
            img:"image/homecrousel/11.jpg"
        },
        {
            img:"image/homecrousel/12.jpg"
        },
        {
            img:"image/homecrousel/13.jpg"
        },

        {
            img:"image/homecrousel/14.jpg"
        },
        {
            img:"image/homecrousel/15.jpg"
        },
        {
            img:"image/homecrousel/16.jpg"
        },

        {
            img:"image/homecrousel/17.jpg"
        },
        {
            img:"image/homecrousel/18.jpg"
        }
    ]
 
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 4 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4,
          slidesToSlide: 4 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        }
      };
    return (
        <div className='w-full  '>
            <div className=' w-full  flex-col py-10 flex justify-center items-center bg-yellow-200'>
                <h1 className='font-medium md:text-[50px] text-[40px]'>About</h1>
            </div>
            <div className='w-full '>
            <Carousel responsive={responsive} >
                   {
                    brand.map(
                        (d,i)=>{
                            return(
                                <div key={i} className='p-2'>
                                <img src={d.img} className='w-full p-4 sm:h-[280px] h-[200px] md:h-[300px]' alt="" />
                            </div>
                            )
                        }
                    )
                   }
                    
                   
                  
                </Carousel>
            </div>
            <div className='container m-auto p-4 '>
                <p className='text-[20px] font-medium'>Founded in 1996 by Abhay Doshi, Ravi Abhay Trading Co has grown from a modest start to become a leading player in the bearing and linear motion industry. Abhay Doshi's vision and dedication to excellence have been the driving forces behind our success and continue to inspire us today.</p>
<br /><br />

                <p className='text-[20px] text-[#7F7F7F]'>DWith over two decades of experience, Ravi Abhay Trading Co has earned a reputation as a trusted provider of premium bearings and linear motion solutions. We proudly offer a diverse range of products from reputable brands such as HCH, KG, KG P2P, F&D, NTN Pillow Block, THK Linear Motion, HIWIN, C.T.C Italy, WIB Swiss, SFERAX, NATEC Japan, and RSI Korea. Our product lineup includes Deep Groove Ball Bearings, Angular Contact Ball Bearings, Linear Guides, Precision Rolled Ball Screws, and more, all designed and manufactured to the highest standards of quality, durability, and efficiency.</p>
                <p className='text-[20px] text-[#7F7F7F] py-4'>
At Ravi Abhay Trading Co, we strive to offer more than just products; we provide comprehensive solutions tailored to meet our customers' unique needs. Our knowledgeable team, guided by Abhay Doshi's vision, is always ready to assist you in finding the right product for your specific application. With expert advice, personalized support, and unmatched customer service, we aim to exceed your expectations at every turn.

                </p>
                <p className='text-[20px] text-[#7F7F7F] py-4'>
                Quality and innovation are core values at Ravi Abhay Trading Co. We understand the importance of reliability and consistency in industrial applications, and we ensure that each product leaving our facility meets the highest standards of quality and performance. Our stringent quality control processes and commitment to continuous improvement guarantee products you can trust. Additionally, we are dedicated to innovation, constantly exploring new technologies and manufacturing processes to enhance our products' performance, efficiency, and durability.
                <br /><br />

At Ravi Abhay Trading Co, we also prioritize environmental sustainability and social responsibility. We strive to minimize our environmental footprint through eco-friendly practices and promote sustainable solutions to our customers. Moreover, we believe in giving back to the community and actively participate in various social responsibility initiatives.



                </p>
                <p className='text-[20px] text-[#7F7F7F] py-4'>
                Over the years, we have built strong relationships with our customers, suppliers, and partners, based on trust, integrity, and mutual respect. Our commitment to these values has enabled us to build a loyal customer base and establish ourselves as a reliable and trusted partner in the industry.
                <br /><br />

At Ravi Abhay Trading Co, we are more than just a bearing and linear motion supplier; we are your trusted partner in success. With Abhay Doshi's vision, decades of industry experience, a comprehensive product portfolio, and a dedicated team, we are confident in our ability to meet and exceed your expectations.
<br /><br />
We invite you to experience the Ravi Abhay Trading Co difference and join our growing family of satisfied customers. Contact us today to learn more about our products and services or to discuss your specific requirements with our team. We look forward to serving you and helping you achieve your business goals.

                </p>
            </div>
           

        </div>
    )
}
