import React from 'react'

export default function Ntn() {
    const ntnbrand = [
        {
            img: "image/ntnbrand/1.webp"
        },
        {
            img: "image/ntnbrand/2.webp"
        },
        {
            img: "image/ntnbrand/3.webp"
        },


    ]

    return (
        <>
        
 
        <div className='container m-auto bg-[#f1eeee]'>
            <div className='   grid md:grid-cols-3 grid-cols-1'>
                <div className=' flex items-center m-5 justify-center  text-center  col-span-1 rounded-lg'>
                    <img className=' w-[200px] h-[200px]  rounded-lg  ' src="image/ntnbrand/ntnlogo.png" alt="" />
                </div>
                <div className='col-span-2 m-5'>
                    <h1 className='font-bold text-[35px]'>
                        NTN BRAND
                    </h1>
                    <p className='py-5 box-border text-[20px]'>NTN is one of the world's largest bearing producers. With manufacturing plants throughout the world, NTN is a leading bearing supplier to both the industrial and automotive markets.<br/>

                        Founded in 1918, NTN has long been recognized for its stringent quality standards. Among its customers are some of the most recognized industrial and automotive brand names around the world and in India.<br/>

                        Headquartered in Osaka, Japan, NTN employs more than 20,000 employees in 27 countries with sales, engineering, production and service networks throughout Japan, the Americas, Europe, Asia and China.<br/>

                        NTN Bearing India Pvt. Ltd is a subsidiary of NTN Corporation Japan and provides marketing and technical support to NTN customers in India: directly and through a well-organized network of Authorized Distributors.<br/>

                        From OEM's to MRO's (Maintenance, Repair, and Overhaul), our customers rely on us for high-performing bearing products that increase the productivity and efficiency of their operations.</p>

                </div>

            </div>
            <div className='m-5'>
                <h1 className='text-[30px] font-medium'>Products</h1>
                <div className=' container m-auto grid md:grid-cols-3 grid-cols-1 '>
                    {
                        ntnbrand.map(
                            (d, i) => {
                                return (
                                    <div className=' border-[3px] rounded-[20px] border-green-500  m-2'>
                                        <img src={d.img} className='rounded-[15px] w-full h-[200px]' alt="" />
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </div>
        </div>
        </>
     
    )

}
