import React from 'react'
import Header from '../Header'
import { Outlet } from 'react-router-dom'
import Footer from '../componate/Footer'
import {  FaWhatsappSquare  } from "react-icons/fa";



export default function Main() {
    return (
        <div>
            <Header />
            <Outlet />
            <Footer/>
            <div className='z-[50000]  bottom-6 fixed   right-6'>
           <a href="https://wa.me/9322250336"><FaWhatsappSquare className='text-[42px] animate-bounce text-blue-500 bg-white rounded-full' /></a>
            </div>
        </div>
    )
}
