

import { Carousel } from "flowbite-react";

export default function Crousal() {
  return (
    <div className="h-56 sm:h-64 xl:h-96 2xl:h-96">
      <Carousel>
        <img src="image/homecrousel/2.jpg" alt="..." />
        <img src="image/homecrousel/30.jpg" alt="..." />
        <img src="image/homecrousel/32.jpg" alt="..." />
      
      </Carousel>
    </div>
  );
}
