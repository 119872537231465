import React from 'react'

export default function Marketing() {
    return (
        <div className='w-full'>
            <div className='w-full  md:px-10 p-2 bg- grid grid-cols-1 lg:grid-cols-3'>
                <div className='col-span-2'>
                    <h1 className=' md:text-[25px] text-[22px] py-5'>Welcome to <span className='font-bold'>RAVI ABHAY TRADING CO.</span></h1>
                    <p className='md:text-[18px] justify-center fontstyle text-[16px]'>Deep Groove Ball Bearings
                        HCH & KG's Deep Groove Ball Bearings (DGBB) stand out as versatile rolling bearings tailored for high-speed applications. Designed to minimize power loss, these bearings adeptly handle radial forces and moderate axial loads in both directions. Their ease of installation makes them a suitable choice for a variety of configurations, ensuring flexibility and efficiency in diverse industrial settings.
                        <br />
                        Angular Contact Ball Bearings
                        KG's Angular Contact Ball Bearings are meticulously crafted to meet the demanding needs of OE manufacturers. Engineered with a specific contact angle, these bearings excel in handling substantial axial and radial loads. Their design often sees them used in pairs or sets, which helps mitigate axial component generation arising from radial loads. This makes them ideal for complex load-bearing applications where precision and efficiency are paramount.
                        <br />
                        Self-Aligning Ball Bearings
                        KG’s Self-Aligning Ball Bearings offer an excellent solution for shafts and housings facing alignment challenges or potential bending. Their design features an outer ring with a spherical raceway, allowing the inner ring and components to deflect and assist in alignment. While they have a small contact angle leading to low axial load capacity, these bearings can tolerate dynamic misalignment ranging from 4 to 7 degrees. This flexibility makes them a reliable choice for applications where alignment precision is crucial but challenging to maintain.
                        <br />
                        Needle Roller Bearings (IKO and INA)
                        Needle Roller Bearings utilize thin needle-shaped rollers to facilitate rotary motion, offering a combination of low sectional height and high load capacity. Their compact design makes them ideal for use in compact machines where space is limited. These bearings play a crucial role in a variety of industries, including automotive, industrial, and construction applications. Their ability to handle high loads despite their slender profile makes Needle Roller Bearings indispensable components for machinery requiring precision and efficiency in challenging environments.
                        <br />
                        Pillow Block and UC Bearings (NTN & TR)
                        Shafts can easily bow under high-speed rotations, leading to reduced performance. Ensure better shaft support within your applications with Bearings Direct's durable and robust Pillow Block Bearings. These bearings are predominantly used in Agricultural Machinery, Rice Milling Machinery, Packaging Machinery, Agricultural/Industrial Blowers, and Three-Dimensional Garages.
                        A Pillow Block Bearing is designed for low-torque, light-loading applications and comprises a mounting bracket, or pillow block, housing the bearing. Typically, this block is securely attached to a foundation or workpiece, allowing the shaft and inner ring of the bearing to rotate freely.

                    </p>
                </div>
                <div className='flex flex-col lg:pt-5 p-4 gap-3 items-center'>
                    <div className='w-full  flex justify-center'>
                        <img className='w-full md:w-[70%] lg:h-[150px]' src="image/marketing/6.jpg" alt="" />
                    </div>
                    <div className='w-full flex justify-center p-4'>
                        <img className='w-[80%] md:w-[70%] sm:h-[150px] lg:h-[100px]' src="image/logo.png" alt="" />

                    </div>
                    <div className='w-full  flex justify-center'>
                        <img className='w-full md:w-[70%] lg:h-[150px]' src="image/marketing/3.jpg" alt="" />
                    </div>
                    <div className='w-full  flex justify-center'>
                        <img className='w-full md:w-[70%] lg:h-[150px]' src="image/marketing/5.jpg" alt="" />
                    </div>
                    <div className='w-full  flex justify-center'>
                        <img className='w-full md:w-[70%] lg:h-[150px]' src="image/marketing/4.jpg" alt="" />
                    </div>
                </div>

            </div>
            <div className='w-[80%] m-auto'>
                <img src="image/homeimg.jpg" className='w-full' alt="" />
            </div>
        </div>
    )
}
