import React from 'react'
import { FaWarehouse,FaMedal,FaBoxes   } from "react-icons/fa";


export default function Experience() {
  return (
    <div className='container m-auto my-5 p-4'>
        <h1 className='md:text-[60px] text-[30px] font-medium'>Trust our experience!</h1>
        <div className='grid grid-cols-1 md:grid-cols-3  gap-5'>
            <div className=' flex  flex-col items-center'>
            <FaWarehouse className='text-[50px] text-center text-[#7F7F7F]' />
                <h1 className='md:text-[28px] text-[20px] px-3 text-center'>Warehouse and Storage Capacity</h1>
            </div>
            <div className=' flex  flex-col items-center md:pt-[100px]  '>
            <FaMedal  className='text-[50px] text-center text-[#7F7F7F]' />
                <h1 className='md:text-[28px] text-[20px] px-3 text-center'>Warehouse and Storage Capacity</h1>
            </div>
            <div className=' flex  flex-col items-center'>
            <FaBoxes  className='text-[50px] text-center text-[#7F7F7F]' />
                <h1 className='md:text-[28px] text-[20px] px-3 text-center'>Warehouse and Storage Capacity</h1>
            </div>

        </div>

    </div>
  )
}
