import React from 'react'

export default function Thk() {
    const ntnbrand = [
        {
            img: "image/thkbrand/1.jpg"
        },
        {
            img: "image/thkbrand/2.jpg"
        },
        {
            img: "image/thkbrand/5.jpg"
        }


    ]

    return (
        <>

    <div className='container m-auto bg-[#f1eeee]'>
            <div className='   grid md:grid-cols-3 grid-cols-1'>
                <div className=' flex items-center m-5 justify-center  text-center  col-span-1 rounded-lg'>
                    <img className=' w-[200px] h-[200px]  rounded-lg  ' src="image/thkbrand/logo.jpg" alt="" />
                </div>
                <div className='col-span-2 m-5'>
                    <h1 className='font-bold text-[35px]'>
                        THK  BRAND
                    </h1>
                    <p className='py-5 box-border text-[20px]'>THK's creative ideas and unique technology made the company worldwide pioneers in the development of the Linear Motion (LM) Guide mechanism. Today, our LM Guide devices are an indispensable component of mechanical and electronic systems in a wide variety of industries.<br/>
                        THK has also developed many other unique mechanical components, including the Ball Spline, Ball Screws and Link Balls, which are manufactured by us for supply to customers worldwide.</p>

                </div>

            </div>
            <div className='m-5'>
                <h1 className='text-[30px] font-medium'>Products</h1>
                <div className=' container m-auto grid md:grid-cols-3 grid-cols-1 '>
                    {
                        ntnbrand.map(
                            (d, i) => {
                                return (
                                    <div className=' border-[3px] rounded-[20px] border-green-500  m-2'>
                                        <img src={d.img} className='rounded-[15px] w-full h-[200px]' alt="" />
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </div>
        </div>
        
        
        </>
      
    )

}
