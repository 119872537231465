import React from 'react'

export default function AllCatlogPdf() {

    const pdf = [
        {
            link: 'files/hch.pdf',
            name: "HCH BRAND"
        },
        {
            link: 'files/kg.pdf',
            name: "KG BRAND"
        },
        {
            link: 'files/hiwin.pdf',
            name: "HIWIN BRAND"
        },
        {
            link: 'files/ntn.pdf',
            name: "NTN BRAND"
        }

    ]
    return (
        <div className='w-full bg-slate-200 p-4'>
            {
                pdf.map(
                    (d, i) => {
                        return (
                            <div key={i} style={{ boxShadow: " rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset" }} className='w-full font-bold py-4 px-20 my-10 flex justify-between items-center'>
                                <h3>{d.name}</h3>
                                <div >
                                    <a href={d.link} download>
                                        Download PDF</a>
                                </div>
                            </div>
                        )
                    }
                )
            }
        </div>
    )
}
