import React from 'react'

export default function Natc() {
    const ntnbrand = [
        {
            img: "image/natcbrand/1.webp"
        },
        {
            img: "image/natcbrand/2.webp"
        },
        {
            img: "image/natcbrand/3.webp"
        }


    ]

    return (

        <>
       
            <div className='container m-auto bg-[#f1eeee]'>
                <div className='   grid md:grid-cols-3 grid-cols-1'>
                    <div className=' flex items-center m-5 justify-center  text-center  col-span-1 rounded-lg'>
                        <img className=' w-[200px] h-[200px]  rounded-lg  ' src="image/natcbrand/logo.png" alt="" />
                    </div>
                    <div className='col-span-2 m-5'>
                        <h1 className='font-bold text-[35px]'>
                            NATC BRAND
                        </h1>
                        <p className='py-5 box-border text-[20px]'>Natec Japan is Specialized in Linear Motion Bearings and Units. The Natec linear bushing consists of an outer cylinder, ball retainer, balls and two end rings. The ball retainer which holds the balls in the recirculating tuucks in held inside the outer cylinder by end rings.</p>

                    </div>

                </div>
                <div className='m-5'>
                    <h1 className='text-[30px] font-medium'>Products</h1>
                    <div className=' container m-auto grid md:grid-cols-3 grid-cols-1 '>
                        {
                            ntnbrand.map(
                                (d, i) => {
                                    return (
                                        <div className=' border-[3px] rounded-[20px] border-green-500  m-2'>
                                            <img src={d.img} className='rounded-[15px] w-full h-[200px]' alt="" />
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                </div>
            </div>

        </>

    )

}
