import React from 'react'
import { FaArrowUp, FaFacebook, FaWhatsappSquare, FaInstagramSquare } from "react-icons/fa";
import { Link } from 'react-router-dom';

export default function Footer() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Adds a smooth scrolling animation
        });
      }

    const navbar = [
        {
            name: "Home",
            link: "/"
        },

        {
            name: "About Us",
            link: "/about"
        },
        {
            name: "Brands",
            link: "/brand"
        },

        {
            name: "Contact",
            link: "/contact"
        }
    ]
    return (
        <footer className='relative text-[white] opacity-4 bg-[black] pt-4 pb-2'>
            <div className='container mx-auto px-4 '>
                <div className='w-full  grid md:grid-cols-3 grid-cols-1 gap-12 md:px-12 py-4'>
                    <div>
                        <h3 className='text-4xl'>Let's keep in touch!</h3>
                        <h5 className='text-lg'>Find us on any of these platforms, we respond 1-2 business days.</h5>
                        <div className='py-4 flex gap-3 text-[32px] '>
                            <span className='cursor-pointer rounded-full bg-[red] '><a href="https://www.facebook.com/HCHbearings?mibextid=kFxxJD"><FaFacebook className='rounded-full' /></a></span>

                            <span className='rounded-full cursor-pointer bg-[red]  '><a href="https://wa.me/9322250336"><FaWhatsappSquare className='rounded-full' /></a></span>

                            <span className='rounded-full cursor-pointer bg-[red] ' ><a href="https://www.instagram.com/hch_bearings_mumbai"><FaInstagramSquare className='rounded-full' /></a></span></div>
                    </div>
                    <div className='md:pl-16'>
                        <span className='block text-[20px ] font-bold pb-2'>USEFUL LINKS</span>
                        <ul onClick={scrollToTop} className="list-unstyled text-[12px]">
                           {
                            navbar.map(
                                (d,i)=>{
                                    return(
                                 <Link to={d.link} ><li key={i} className=' block text-[15px] pb-2 hover:text-[#5858c2]'>{d.name}</li></Link>
                                    )
                                }
                            )
                           }
                           
                        </ul>
                    </div>
                    <div>
                        <h2 className='font-bold'>REGISTATION OFFICE</h2>
                        <ul>
                            <li className='mt-2'>8/16,Narayan Dhuru Cross Lane, 2nd Floor Bandra House,Room No 15, Mumbai-400003 , India</li>
                            <a href='mailto:Raviabhay@hotmail.com' className='mt-2'>Raviabhay@hotmail.com</a><br />
                            <a href='tel:+919322250336' className='mt-2'>+919322250336</a> ,<a href="tel:+919930587132">+919930587132</a> <a href="tel:+912240056127">+912240056127</a>
                        </ul>
                    </div>
                </div>
                <div className='w-full  flex justify-end'>
                    <button className="animate-bounce inline-block  text-[white] border-2 border-[#0400ff] rounded-full p-3 box-border" ><FaArrowUp  onClick={scrollToTop}/></button>
                </div>
                <hr className='w-full my-2 border-gray-500' />
                <div className='w-full flex justify-center items-center font-bold'>All Right Reserved. Copyright © RAVI ABHAY TRADING CO.</div>
            </div>
        </footer>
    )
}
