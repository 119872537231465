import React, { useState } from 'react'
import { FaWhatsapp } from "react-icons/fa";
import { FaBars } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { Link } from 'react-router-dom';
import { IoHomeOutline } from "react-icons/io5";
import { FaPenNib } from "react-icons/fa";
import { TbBrandDatabricks } from "react-icons/tb";
import { BiPhoneCall } from "react-icons/bi";



export default function Header() {
    const [toggle, settoggle] = useState(false)

   
    function demo() {
        settoggle(!toggle)
    }
    function navbarout() {
        settoggle(false)
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Adds a smooth scrolling animation
        });
      }



    return (
        <header style={{ boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }} className='w-full  flex items-center justify-between   box-border bg-white z-[222222222] '>
            <img src="image/logo.jpg" className='md:w-[10%] p-4 w-[14%]  ml-9 ' alt="" />
            <ul onClick={scrollToTop} className='md:flex w-[70%] justify-around   items-center text-[18px] font-semibold hidden '>

                <Link to={'/'}><li className='head box-top px-4   h-[90px]   text-center flex justify-center gap-2 items-center  cursor-pointer'> <IoHomeOutline />Home</li></Link>

                <Link to={'/about'}><li className='head box-top  px-4 h-[90px]   text-center flex justify-center gap-2 items-center  cursor-pointer'> <FaPenNib />About Us</li></Link>

                <Link to={'/brand'}><li className='head px-4 group box-top relative  h-[90px]   text-center flex justify-center gap-2 items-center  cursor-pointer '> <TbBrandDatabricks />Brands
                    
                </li></Link>

                <Link to={'/contact'}><li className='head box-top px-4   h-[90px]   text-center flex justify-center gap-2 items-center  cursor-pointer'> <BiPhoneCall />Contact</li></Link>






            </ul>


            {
                toggle == false ? <FaBars onClick={demo} className=' cursor-pointer text-[22px] md:hidden flex absolute right-6' />
                    :
                    <ImCross style={{ zIndex: "40" }} onClick={demo} className='md:hidden  cursor-pointer  flex absolute right-6' />
            }


            <ul onClick={navbarout} style={{ zIndex: '22' }} className={` md:hidden justify-around items-center  text-[16px] h-screen font-bold absolute pt-[50px] duration-500  top-0 gap-2 pb-[100px] bg-white w-full flex flex-col ${toggle == false ? 'hidden' : 'block'} ${toggle == true ? 'left-0' : "left-[-100%]"} `}>
                <Link to={'/'}><li className='      text-center flex justify-center gap-2 items-center  cursor-pointer'> <IoHomeOutline />Home</li></Link>

                <Link to={'/about'}><li className='      text-center flex justify-center gap-2 items-center  cursor-pointer'> <FaPenNib />About Us</li></Link>

                <Link to={'/brand'}><li className=' box-top relative     text-center flex justify-center gap-2 items-center flex-col  cursor-pointer '> <span className='flex justify-center items-center gap-3'><TbBrandDatabricks />Brands </span></li></Link>

                <Link to={'/contact'}><li className='    text-center flex justify-center gap-2 items-center  cursor-pointer'> <BiPhoneCall />Contact</li></Link>

            </ul>

        </header>
    )
}

