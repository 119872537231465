import React from 'react'
import About from '../componate/AboutComp/About'
import Vision from '../componate/AboutComp/Vision '
import Founders from '../componate/AboutComp/Founders'
import Experience from '../componate/AboutComp/Experience'
import Journey from '../componate/AboutComp/Journey'

export default function AboutPage() {
  return (
    <>
      <About/>
      <Vision/>
      <Experience/>
    </>
  )
}
