import React from 'react'
import Our from '../Our'
import Crousal from '../componate/Crousal'
import Marketing from '../componate/Marketing'
import Service from '../componate/Service'
import Slider from '../componate/Slider'
import AllCatlogPdf from '../componate/AllCatlogPdf'

export default function HomePage() {
  return (
    <>
      <Crousal/>
      <Marketing/>
      <AllCatlogPdf/>
      <Service/>
      <Slider/>
    </>
  )
}
