import React from 'react'
import { FaToolbox,FaCubes,FaMedal,FaShippingFast,FaCheckCircle    } from "react-icons/fa";


export default function Service() {
    return (
        <img className='w-full my-10' src="image/homecrousel/31.png" alt="..." />

    )
}
