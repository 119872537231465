import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import Main from './page/Main'
import HomePage from './page/HomePage'
import AboutPage from './page/AboutPage'
import BrandPage from './page/BrandPage'
import ContactPage from './page/ContactPage'
import Fnd from './componate/AllbrandPage/Fnd'
import Hcl from './componate/AllbrandPage/Hcl'
import Hiwik from './componate/AllbrandPage/Hiwin'
import Hl from './componate/AllbrandPage/Hl'
import Kg from './componate/AllbrandPage/Kg'
import Natc from './componate/AllbrandPage/Natc'
import Ntn from './componate/AllbrandPage/Ntn'
import Rsi from './componate/AllbrandPage/Rsi'
import Thk from './componate/AllbrandPage/Thk'



const routers=createBrowserRouter(
  [
    {
      path:'/',
      element:<Main/>,
      children:[
        {
          path:'/',
          element:<HomePage/>
        },
        {
          path:'/about',
          element:<AboutPage/>
        },
        {
          path:'/brand',
          element:<BrandPage/>
        },
        {
          path:'/contact',
          element:<ContactPage/>
        },
        {
          path:'/f&d_brand',
          element:<Fnd/>
        },
        {
          path:'/hch_brand',
          element:<Hcl/>
        },
        {
          path:'/hiwik_brand',
          element:<Hiwik/>
        },
        {
          path:'/hl_brands',
          element:<Hl/>
        },
        {
          path:'/kg_brand',
          element:<Kg/>
        },
        {
          path:'/natec_brand',
          element:<Natc/>
        },
        {
          path:'/ntn_brand',
          element:<Ntn/>
        }, {
          path:'/rsi_brand',
          element:<Rsi/>
        } ,{
          path:'/thk_brand',
          element:<Thk/>
        }
      ]
    }
  ]
)
export default function App() {
  return (
    <RouterProvider router={routers}/>
  )
}
