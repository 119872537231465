import React from 'react'

export default function Hcl() {
    const hclbrand = [
        {
            img: "image/Hclbrand/1.jpg"
        },
        {
            img: "image/Hclbrand/2.jpg"
        },
        {
            img: "image/Hclbrand/19.jpg"
        }

    ]

    return (
        <>
     
            <div className='container m-auto bg-[#f1eeee]'>
                <div className='   grid md:grid-cols-3 grid-cols-1'>
                    <div className=' flex items-center m-5 justify-center  text-center  col-span-1 rounded-lg'>
                        <img className=' w-[200px] h-[200px]  rounded-lg  ' src="image/Hclbrand/logo.gif" alt="" />
                    </div>
                    <div className='col-span-2 m-5'>
                        <h1 className='font-bold text-[35px]'>
                            HCH BRAND
                        </h1>
                        <p className='py-5 box-border text-[20px]'>HCH is the largest precision quality Chinese manufacturer of miniature, small, and medium-size ball bearings, manufacturing a broad range of inch and metric deep-groove ball bearings and tapered roller bearings. HCH is one of the top 500 privately-held companies in China, currently operating one of the world’s largest deep-groove ball bearing manufacturing facility with 517 production lines. HCH brand products are used in wide applications e.g. automotive division for applications in engines, transmissions, chassis, alternator and starter motors.</p>

                    </div>

                </div>
                <div className='m-5'>
                    <h1 className='text-[30px] font-medium'>Products</h1>
                    <div className=' container m-auto grid md:grid-cols-3 grid-cols-1 '>
                        {
                            hclbrand.map(
                                (d, i) => {
                                    return (
                                        <div className=' border-[3px] rounded-[20px] border-green-500  m-2'>
                                            <img src={d.img} className='rounded-[15px] w-full h-[200px]' alt="" />
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )

}
