import React from 'react'
import { Link } from 'react-router-dom'
export default function Brand() {
    const brands = [
        {
            img: "image/Hclbrand/logo.gif",
            name: 'HCH BRANDS',
            link:"/hch_brand"
        },
        {
            img: "image/kgbrand/logo.png",
            name: 'KG BRANDS',
            link:"/kg_brand"

        },
        {
            img: "image/ntnbrand/ntnlogo.png",
            name: 'NTN BRANDS',
            link:"/ntn_brand"

        },
        {
            img: "image/f&dbrand/log0.webp",
            name: 'F&D BRANDS',
            link:"/f&d_brand"

        },
        {
            img: "image/hiwinbrand/logo.webp",
            name: 'HIWIN BRANDS',
            link:"/hiwik_brand"

        },
        {
            img: "image/thkbrand/logo.jpg",
            name: 'THK BRANDS',
            link:"/thk_brand"

        },
        {
            img: "image/natcbrand/logo.png",
            name: 'NATEC BRANDS',
            link:"/natec_brand"

        },
        {
            img: "image/rsibrand/logo.jpg",
            name: 'RSI BRANDS',
            link:"/rsi_brand"

        },
        {
            img: "image/hl/logo.jpeg",
            name: 'HL BRANDS',
            link:"/hl_brands"

        }

    ]

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Adds a smooth scrolling animation
        });
      }
    

   
    return (
        <div className='w-full '>
            <div className=' w-full  flex-col py-10 flex justify-center items-center bg-yellow-200'>
                <h1 className='font-medium md:text-[50px] text-[40px]'>Brand</h1>
            </div>

            <div className=' container m-auto my-2  grid md:grid-cols-3 gap-3 grid-cols-1 ='>
                {
                    brands.map(
                        (d, i) => {
                            return (
                                <div className=' p-4 border hover:shadow-lg'>
                                    <div className='max-w-sm h-[200px] m-auto'>
                                        <img src={d.img} className='m-auto w-[90%] h-[90%]  ' alt="" />
                                    </div>
                                    <div className='w-full text-[19px] p-3 '>
                                        <h2 className='text-[#635f5f] text-center  font-semibold capitalize text-[12px] md:text-[16px]  my-2'>{d.name}</h2>
                                       <Link onClick={scrollToTop   } to={d.link}>
                                       <button className='border border-yellow-600 rounded w-full   text-[11px] md:text-[16px]  py-2 my-2 md:px-3  text-[#d79252] hover:text-white hover:bg-[#af8529]'>View Full Details</button>
                                        
                                        </Link> 

                                        
                                    </div>
                                </div>
                            )
                        }
                    )
                }

            </div>

        </div>
    )
}



