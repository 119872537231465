import React from 'react'
import { FaMapPin } from "react-icons/fa";

export default function Contact() {
    return (
        <div className='w-full '>
            <div className='w-full flex justify-center items-center py-6 bg-yellow-200'>
                <h1 className=' text-[40px] md:text-[50px]'>Contact</h1>
            </div>
            <div className='container m-auto bg-white p-4 md:gap-20 gap-16 grid md:grid-cols-2 grid-cols-1'>


                <form action="">
                    <div className='w-full text-[25px] py-4'>Get in Touch</div>
                    <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-4">
                        <div class="md:col-span-2">
                            <label for="full_name">Full Name</label>
                            <input type="text" name="full_name" id="full_name" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                        </div>
                        <div class="md:col-span-2">
                            <label for="full_name">Last Name</label>
                            <input type="text" name="lastname" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                        </div>
                        <div class="md:col-span-4">
                            <label for="email">Email Address</label>
                            <input type="text" name="email" id="email" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" placeholder="email@domain.com" />
                        </div>
                        <div class="md:col-span-4">
                            <label for="email">Subject</label>
                            <input type="text" name="subject" id="subject" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                        </div>
                        <div class="md:col-span-4">
                            <label for="phone">Phone</label>
                            <input type="number" name="phone" id="phone" class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                        </div>


                        <div class="md:col-span-4">
                            <label for="city">Message</label>
                            <textarea className='border w-full bg-gray-50  ' name="" id="" cols="" rows="5"></textarea>
                        </div>

                        <div class="md:col-span-5 text-start">
                            <div class="inline-flex items-end">
                                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
                            </div>
                        </div>



                    </div>
                </form>

                <div className='flex mt-20 flex-col gap-10'>

                    <div className='w-full bg-[#8080808a] flex gap-2  p-4'>
                        <FaMapPin className='text-[25px] mt-2 text-green-800' />
                        <div className='flex  flex-col gap-2'>
                            <h1 className='text-[22px]'>
                                Mumbai Office</h1>
                            <p className='text-[16px]'>Ravi Abhay Trading Co 8/16 Narayan Dhuru X lane (Area Nagdevi) 2nd floor,Banda House, R.no. 15, Mumbai Maharashtra India 400003
<br />
Near Mumbadevi/Kalbadevi
Abdul Rehman Street
                            </p>
                            <a href='mailto:Raviabhay@hotmail.com' className='mt-2'>Raviabhay@hotmail.com</a>
                            <p className='text-[16px] text-green-800'>
                            <a href='tel:+919322250336' className='mt-2'>+919322250336</a>
                            <br />
                             <a href="tel:+919322033330">+91-9322033330</a> 
                             <br />
                   <a href="tel:+918779656504">+91-8779656504</a>
                   <br />
                             <a href="tel:+919930587132">+91-9930587132
</a>
<br />
                             <a href="tel:+918080558686">+91-8080558686
</a>
<br />
                             <a href="tel:+912240056127">+912240056127</a>

<br />
GST no -27ABUPD6055D1ZL
<br />
MSME UDYAM MH-19-0185702 SMALL
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
