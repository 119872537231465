import React from 'react'
import Contact from '../Contact'
import Map from '../Map'

export default function ContactPage() {
  return (
    <>
      
      <Contact/>
      <Map/>
    </>
  )
}
