import React from 'react'

export default function Map() {
  return (
    <div className='w-full my-2 '>
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3773.568944402029!2d72.83338540000001!3d18.950464700000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7ce238c1bd5bb%3A0x9bb6a8a389743b6b!2s16%2C%20Narayan%20Dhuru%20St%2C%20Khokha%20Bazar%2C%20Chippi%20Chawl%2C%20Masjid%20Bandar%2C%20Mumbai%2C%20Maharashtra%20400003!5e0!3m2!1sen!2sin!4v1713282457737!5m2!1sen!2sin" className='w-full h-[70vh]' ></iframe>
    </div>


  )
}
