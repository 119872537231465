import React from 'react'

export default function Eaqury() {
    return (
        <div className='w-full p-4 bg-gray-100'>
            <div className='w-[70%] m-auto flex-col flex items-center'>
                <h1 className='text-[32px] font-semibold '>Enquire Now​</h1>
                <form action="" className='w-full'>
                    <div className='grid sm:grid-cols-3 gap-3 sm:my-8 my-5 grid-cols-1'>
                        <input className='w-full  border px-2 py-1' type="text" placeholder='Enter mame*' />
                        <input className='w-full  border px-2 py-1' type="text" placeholder='Enter phone' />
                        <input className='w-full  border px-2 py-1' type="text" placeholder='Enter email*' />
                    </div>
                    <div className='grid sm:grid-cols-2 gap-3 grid-cols-1'>
                        <input className='w-full  border px-2 py-1' type="text" placeholder='Enter Conpany name*' />
                        <select className='w-full  border px-2 py-1' name="" id="">
                            <option value="">Products*</option>
                            <option value="">Products*</option>
                            <option value="">Products*</option>
                            <option value="">Products*</option>
                        </select>
                    </div>
                    <div className='flex justify-center items-center p-4 my-6'>
                        <button className='border px-6 text-white py-2 bg-blue-600 text-[20px] rounded-lg'>Submit</button>
                    </div>

                </form>
            </div>
        </div>
    )
}
