import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";



const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 2
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

export default function Slider() {

    const slider=[
        {
            img:"image/slider/1.jpg",
            name:"conveyous"
        },
        {
            img:"image/slider/2.jpg",
            name:"motors"
        },
        {
            img:"image/slider/3.jpg",
            name:"Automotive"
        },
        {
            img:"image/slider/4.jpg",
            name:"houseHold"

        },


        {
            img:"image/slider/5.jpg",
            name:"gearboxes"

        },
        {
            img:"image/slider/13.jpg",
            name:"compressors"

        },

        {
            img:"image/slider/7.jpg",
            name:"fan"

        },
        {
            img:"image/slider/8.jpg",
            name:"pumps"
        },
         {
            img:"image/slider/9.jpg",
            name:"precessions machines"
        }, 
        {
            img:"image/slider/11.jpg",
            name:"lifts"
        },
        {
            img:"image/slider/6.png",
            name:"heavy vehicles"
        },
        
    ]

    const brands = [
        {
            img: "image/homecrousel/2.jpg",
        },
        {
            img: "image/kgbrand/logo.png",

        },
        {
            img: "image/ntnbrand/ntnlogo.png",

        },
        {
            img: "image/f&dbrand/log0.webp",

        },
        {
            img: "image/hiwinbrand/logo.webp",

        },
        {
            img: "image/thkbrand/logo.jpg",

        },
        {
            img: "image/natcbrand/logo.png",

        },
        {
            img: "image/rsibrand/logo.jpg",
        },
    
        {
            img: "image/hl/logo.jpeg",

        }

    ]

    return (
        <div className='container m-auto grid grid-cols-1 md:grid-cols-2 my-10 '>
            <div className='px-5 py-5'>
                <div className='text-center text-[35px] font-bold font-sans'>
                    <h1>Industry Solutions</h1>
                </div>
                <Carousel initialSlide responsive={responsive}  autoPlay>
                   {
                    slider.map(
                        (d,i)=>{
                            return(
                                <div key={i} className='p-2 '>
                                <img src={d.img} className='w-full' alt="" />
                                <h1 className='text-center text-gray-400 capitalize '>{d.name}</h1>
                            </div>
                            )
                        }
                    )
                   }
                   
                    <div className='p-2'>
                        <img src="image/slider/6.jpg" className='w-full' alt="" />
                    </div>
                </Carousel>
            </div>
            <div className='px-5 py-5 md:border-l border-[black]'>
                <div className='text-center text-[35px] font-bold'>
                    <h1 className='font-sans'>Our Partners</h1>
                </div>
                <Carousel responsive={responsive} autoPlay>
                   {
                    brands.map(
                        (d,i)=>{
                            return(
                                <div key={i} className='p-2 flex items-center '>
                                <img src={d.img} className='w-full h-[200px] ' alt="" />
                            </div>
                            )
                        }
                    )
                   }
                    
                   
                  
                </Carousel>
            </div>
           

        </div>
    )
}
