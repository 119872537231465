import React from 'react'



export default function Fnd() {
    const ntnbrand = [
        {
            img: "image/f&dbrand/1.webp"
        },
        {
            img: "image/f&dbrand/2.webp"
        },
        {
            img: "image/f&dbrand/3.webp"
        },


    ]

    return (
      <>

    
        <div className='container m-auto bg-[#f1eeee]'>
            <div className='   grid md:grid-cols-3 grid-cols-1'>
                <div className=' flex items-center m-5 justify-center  text-center  col-span-1 rounded-lg'>
                    <img className=' w-[200px] h-[200px]  rounded-lg  ' src="image/f&dbrand/log0.webp" alt="" />
             

                </div>
                <div className='col-span-2 m-5'>
                    <h1 className='font-bold text-[35px]'>
                    F&D BRAND
                    </h1>
                    <p className='py-5 box-border text-[20px]'>Fuda Bearing Corporation Co., Ltd was founded in 1996, having 1000 employees and 280 million ball bearing annual production capacity. At present, the factory independently makes steel tubes in house, owns 6 automatic heat treatment production lines and dozens of automatic assembly lines.<br/>

                        F&D is the private brand of Fuda Bearing. The featured products are Precision Deep Groove Ball Bearings, with inner diameter ranging from 10mm to 90mm. All production lines including steel tubes making, heat treatment, grinding, super finishing, assembly and inspection are 100% automatic, to ensure the stable and high quality. With 20 years experience in bearing industry , the factory has ISO 9001, IATF 16949 and ISO 14001 Environment certified, as well as TQM Quality Management System approved.</p>

                </div>

            </div>
            <div className='m-5'>
                <h1 className='text-[30px] font-medium'>Products</h1>
                <div className=' container m-auto grid md:grid-cols-3 grid-cols-1 '>
                    {
                        ntnbrand.map(
                            (d, i) => {
                                return (
                                    <div className=' border-[3px] rounded-[20px] border-green-500  m-2'>
                                        <img src={d.img} className='rounded-[15px] w-full h-[200px]' alt="" />
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </div>
        </div>

      
      </>
    )

}
