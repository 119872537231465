import React from 'react'

export default function Hiwin() {
    const ntnbrand = [
        {
            img: "image/hiwinbrand/1.jpg"
        },
        {
            img: "image/hiwinbrand/2.jpg"
        },
        {
            img: "image/hiwinbrand/3.webp"
        }


    ]

    return (
        <>


        

            <div className='container m-auto bg-[#f1eeee]'>
                <div className='   grid md:grid-cols-3 grid-cols-1'>
                    <div className=' flex items-center m-5 justify-center  text-center  col-span-1 rounded-lg'>
                        <img className=' w-[200px] h-[200px]  rounded-lg  ' src="image/hiwinbrand/logo.webp" alt="" />
                    </div>
                    <div className='col-span-2 m-5'>
                        <h1 className='font-bold text-[35px]'>
                            HIWIN BRAND
                        </h1>
                        <p className='py-5 box-border text-[20px]'>HIWIN comes from "HI-tech WINner" and means, " With us, you are a hi-tech winner." As you incorporate our product within your applications, you will realize the uncompromising value and the leading technological advancements available through HIWIN.<br />

                            In addition, we are proud of being recognized by the industry for our innovation technology. The principle of providing our customers with greater value through technological advancements and enhanced global competition is the foundation of HIWIN’s plan to be the leader in hi-tech industry.</p>

                    </div>

                </div>
                <div className='m-5'>
                    <h1 className='text-[30px] font-medium'>Products</h1>
                    <div className=' container m-auto grid md:grid-cols-3 grid-cols-1 '>
                        {
                            ntnbrand.map(
                                (d, i) => {
                                    return (
                                        <div className=' border-[3px] rounded-[20px] border-green-500  m-2'>
                                            <img src={d.img} className='rounded-[15px] w-full h-[200px]' alt="" />
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                </div>
            </div>


        </>


    )

}
